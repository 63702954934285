import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["container"];
  static values = { maxAnswers: Number };

  connect() {
    this.manageInputs();
  }

  handleCheckboxes(e) {
    this.manageInputs();
  }

  get questionValue() {
    return this.element.dataset.questionValue;
  }

  disableInputsOnMaxAmount(checkedCount, uncheckedCollection) {
    uncheckedCollection.forEach((element) => {
      element.dataset.checkboxOptionDisabledByMaxCountValue = JSON.stringify(
        checkedCount >= this.maxAnswersValue
      );
    });
  }

  disableOptions(e) {
    let { detail: { disableOptions, optionValue, questionValue }, } = e;
    let optionToDisable = disableOptions[this.questionValue];

    if (!optionToDisable) return;

    optionToDisable.forEach((option) => {
      let element = this.element.querySelector(
        `[data-option-identifier="${option}"]`
      );
      if (!element) return;

      let disabledByOptions = getElementCheckboxDisabledByOptionsList(element);
      disabledByOptions[questionValue] = _.chain(disabledByOptions)
        .get(questionValue, [])
        .concat([optionValue])
        .uniq()
        .value();

      updateElementCheckboxDisabledByOptionsList(element, disabledByOptions);
    });

    // This will enable options in case when max options were selected and some of these options was disabled
    setTimeout(() => this.manageInputs(), 100);
  }

  enableOptions(e) {
    let { detail: { disableOptions, optionValue, questionValue }, } = e;
    let optionToDisable = disableOptions[this.questionValue];

    if (!optionToDisable) return;

    optionToDisable.forEach((option) => {
      let element = this.element.querySelector(
        `[data-option-identifier="${option}"]`
      );
      if (!element) return;

      let disabledByOptions = getElementCheckboxDisabledByOptionsList(element);
      disabledByOptions[questionValue] = _.chain(disabledByOptions)
        .get(questionValue, [])
        .filter((val) => val != optionValue)
        .uniq()
        .value();

      updateElementCheckboxDisabledByOptionsList(element, disabledByOptions);
    });
  }

  manageInputs() {
    if (!this.maxAnswersValue) return;

    let checkedCount =
      this.containerTarget.querySelectorAll("input:checked").length;
    let uncheckedCollection = this.containerTarget.querySelectorAll(
      "input:not(:checked)"
    );

    this.disableInputsOnMaxAmount(checkedCount, uncheckedCollection);
  }
}

function updateElementCheckboxDisabledByOptionsList(element, value) {
  element.dataset.checkboxOptionDisabledByOptionsValue = JSON.stringify(value);
}

function getElementCheckboxDisabledByOptionsList(element) {
  return JSON.parse(_.get(element.dataset, "checkboxOptionDisabledByOptionsValue", "{}"));
}
