function fillBars(cities) {
  cities.each(function () {
    var index = this.dataset.index;
    var filledContainerWidth = parseInt(index * 100) + "%";
    var animationDuration = index * 1500;

    $(this).find(".index-bar__filler").animate(
      {
        width: filledContainerWidth,
      },
      animationDuration
    );
  });
}

function fillVerticalBar(verticalBar) {
  var index = verticalBar.dataset.index;
  // SETTING MINIMAL INDEX VALUE POSITION, SO IT WILL BE DISPLAYED CORRECTLY
  var indexValuePosition = index * 100 > 20 ? index * 100 : 20;

  var filledContainerHeight = parseInt(index * 100) + "%";
  var indexValuePosition = parseInt(100 - indexValuePosition) + "%";
  var animationDuration = index * 1500;

  $(".index-bar__vertical .index-bar__vertical__filler").animate(
    {
      height: filledContainerHeight,
    },
    animationDuration
  );

  $(".index-bar__vertical .index-value").animate(
    {
      top: indexValuePosition,
    },
    animationDuration
  );

  if (index * 100 > 30) {
    $(".index-bar__vertical .index-bar__vertical__filler").animate(
      {
        fontSize: "45px",
      },
      animationDuration
    );
  }
}

$(document).ready(function () {
  var cities = $(".index-bar");
  var verticalBar = $(".index-bar__vertical")[0];

  cities.length > 0 && fillBars(cities);
  verticalBar !== undefined && fillVerticalBar(verticalBar);
});
