import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];
  static values = { url: String };

  updateInput() {
    $.ajax({
      type: "PATCH",
      url: this.urlValue,
      data: {
        indicator: { value: this.inputTarget.value },
      },
    });
  }
}
