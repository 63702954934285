import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["menuItem"];

  connect() {
    setTimeout(() => {
      this.updateMenu();
      this.updateActiveLink();
    }, 100);
  }

  get currentScroll() {
    return window.scrollY + this.padding;
  }

  get padding() {
    return document.body.offsetWidth > 992 ? 20 : 104;
  }

  get scrolledToEnd() {
    return (
      window.scrollY + window.innerHeight ===
      document.documentElement.offsetHeight
    );
  }

  get scrolledToTop() {
    return window.scrollY === 0;
  }

  get commentsSectionTop() {
    let sidebarMenuHeight = 80;

    return document.querySelector("#fb-comments").offsetTop - sidebarMenuHeight;
  }

  updateMenu() {
    let comments = document.querySelector("#fb-comments");

    if (
      this.currentScroll >= this.fixmeTop &&
      (comments == null || this.currentScroll <= this.commentsSectionTop)
    ) {
      if (!this.fixmeTop) this.fixmeTop = getElementOffsetTop(this.element);
      this.element.classList.add("sticked");
    } else {
      this.element.classList.remove("sticked");
      this.fixmeTop = getElementOffsetTop(this.element);
    }
  }

  updateActiveLink() {
    if (this.data.get("linksDisabled")) return;
    const currentActiveElement = this.element.querySelector("a.active");
    let element;

    if (this.scrolledToTop) {
      element = _.first(this.menuItemTargets);
    } else if (this.scrolledToEnd) {
      element = _.last(this.menuItemTargets);
    } else {
      const targets = [...this.menuItemTargets].map((element, index) => {
        const targetedElement = document.getElementById(element.dataset.menuId);
        const { top, bottom } = targetedElement.getBoundingClientRect();
        const elHeight = targetedElement.offsetHeight;
        const windowHeight = window.innerHeight;

        const inViewport = Math.max(
          0,
          top > 0
            ? Math.min(elHeight, windowHeight - top)
            : Math.min(bottom, windowHeight)
        );

        const screenTop = document.documentElement.scrollTop;
        const screenBottom =
          document.documentElement.scrollTop + $(window).height();
        const boxTop = $(targetedElement).offset().top;
        const boxHeight = $(targetedElement).height();
        const boxBottom = boxTop + boxHeight;
        let percent = 0;

        if (boxTop > screenTop) {
          if (boxBottom < screenBottom) {
            percent = 100;
          } else if (boxTop < screenBottom) {
            percent = Math.round(((screenBottom - boxTop) / boxHeight) * 100);
          }
        } else if (boxBottom > screenTop) {
          percent = Math.round(((boxBottom - screenTop) / boxHeight) * 100);
        }

        return { element, inViewport, percent, index };
      });

      if (_.find(targets, ["percent", 100])) {
        element = _.chain(targets)
          .filter(({ percent }) => percent == 100)
          .sortBy("index")
          .first()
          .value().element;
      } else {
        element = _.last(_.sortBy(targets, "inViewport")).element;
      }
    }

    element.classList.add("active");
    if (currentActiveElement && currentActiveElement != element)
      currentActiveElement.classList.remove("active");
  }

  scroll(e) {
    this.updateMenu();
    this.updateActiveLink();
  }
}

function getElementOffsetTop(element) {
  return element.getBoundingClientRect().top + window.pageYOffset;
}
