import { Controller } from "stimulus";
import _ from 'lodash';

export default class extends Controller {
  static values = { currentDisabledQuestions: Array, currentDisabledQuestionsOptions: Object, currentSelectedOption: String };

  connect() {
    this.currentDisabledQuestionsOptions = {};

    // Timeout is needed to let all questions mount on page
    setTimeout(() => {
      const currentOption = this.element.querySelector('input:checked');
      if (!currentOption) return;
      let questionsOptionsToDisable = JSON.parse(currentOption.dataset.disableQuestionsOptionsValue);
      this.triggerDisableQuestionOptionsEvent(currentOption.dataset.optionIdentifier, questionsOptionsToDisable);
    }, 50);
  }

  change(e) {
    let questionsToDisable = JSON.parse(e.target.dataset.disableQuestionsValue);
    let questionsOptionsToDisable = JSON.parse(e.target.dataset.disableQuestionsOptionsValue);
    this.triggerDisableQuestionEvent(questionsToDisable);
    this.triggerDisableQuestionOptionsEvent(e.target.dataset.optionIdentifier, questionsOptionsToDisable);
  }

  get questionValue() {
    return this.element.dataset.questionValue;
  }

  triggerDisableQuestionEvent(questionsToDisable) {
    if(questionsToDisable.length > 0) {
      document
          .dispatchEvent(new CustomEvent('disable-questions', {
            detail: {
              disableQuestions: questionsToDisable,
            }
          }))
    }

    let questionsToEnable = _.difference(this.currentDisabledQuestionsValue, questionsToDisable)
    if(questionsToEnable.length > 0) {
      document
          .dispatchEvent(new CustomEvent('enable-questions', {
            detail: {
              enableQuestions: questionsToEnable,
            }
          }))
    }

    this.currentDisabledQuestionsValue = questionsToDisable;
  }

  triggerDisableQuestionOptionsEvent(optionValue, questionsOptionsToDisable) {
    if(_.isEqual(questionsOptionsToDisable, this.currentDisabledQuestionsOptionsValue)) return;

    document.dispatchEvent(
      new CustomEvent("disable-options", {
        detail: {
          disableOptions: questionsOptionsToDisable,
          optionValue: optionValue,
          questionValue: this.questionValue,
        },
      })
    );

    if(this.currentSelectedOption)
      document.dispatchEvent(
        new CustomEvent("enable-options", {
          detail: {
            disableOptions: this.currentDisabledQuestionsOptions,
            optionValue: this.currentSelectedOption,
            questionValue: this.questionValue,
          },
        })
      );

    this.currentDisabledQuestionsOptions = questionsOptionsToDisable;
    this.currentSelectedOption = optionValue;
  }

  disableQuestion(e) {
    let { detail: { disableQuestions } } = e;
    if(!_.includes(disableQuestions, this.questionValue)) return;
    
    this.element.querySelectorAll('input').forEach((element) => {
      element.disabled = true;
      element.checked = false;
    })
  }

  enableQuestion(e) {
    let { detail: { enableQuestions } } = e;
    if(!_.includes(enableQuestions, this.questionValue)) return;

    this.element.querySelectorAll('input').forEach((element) => {
      element.disabled = false;
    })
  }
}
