import { Controller } from "stimulus";
import L from "leaflet";
import _ from "lodash";
import React from "react";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom";

import { CityInfo, CityTooltip } from "../bundles/CityInfo";

import Map from "./map.json";
import CityIcon from "./city_icon.svg";
import CityIconHover from "./city_icon_hover.svg";
import "leaflet/dist/leaflet.css";

const cityIcon = L.icon({
  iconUrl: CityIcon,
  iconSize: [13, 13],
  iconAnchor: [6.6, 6.6],
});

const cityIconHover = L.icon({
  iconUrl: CityIconHover,
  iconSize: [20, 20],
  iconAnchor: [10, 10],
});

const cityDataToChartData = (data) => {
  const { man_requests, woman_requests, age_14_18, age_19_23, age_24_35 } =
    data;
  const total_requests = man_requests + woman_requests;
  const man_requests_percentage = Math.floor(
    man_requests / (total_requests / 100)
  );
  const total_requests_by_age = age_14_18 + age_19_23 + age_24_35;

  return {
    total_requests,
    man_requests_percentage: man_requests_percentage,
    woman_requests_percentage: 100 - man_requests_percentage,
    chart_data: [
      Math.floor(age_14_18 / (total_requests_by_age / 100)),
      Math.floor(age_19_23 / (total_requests_by_age / 100)),
      Math.floor(age_24_35 / (total_requests_by_age / 100)),
    ],
  };
};

export default class extends Controller {
  static values = { cities: Array };
  static targets = ["map"];

  connect() {
    this.totalCitiesData = _.reduce(this.citiesValue, (prev, cur) => {
      return {
        woman_requests: prev.woman_requests + cur.woman_requests,
        man_requests: prev.man_requests + cur.man_requests,
        age_14_18: prev.age_14_18 + cur.age_14_18,
        age_19_23: prev.age_19_23 + cur.age_19_23,
        age_24_35: prev.age_24_35 + cur.age_24_35,
      };
    });

    this.initializeMap();
  }

  initializeMap() {
    this.map = L.map(this.mapTarget, {
      zoomControl: false,
      scrollWheelZoom: false,
      doubleClickZoom: false,
      boxZoom: false,
      zoomSnap: 0,
      attributionControl: false,
      dragging: false,
    });

    this.info = new L.control({ position: "bottomleft" });
    this.info.onAdd = () => {
      this.info._div = L.DomUtil.create("div", "index-map-info__wrapper");
      this.info.update();
      return this.info._div;
    };

    this.info.update = (city) => {
      const stat = city || this.totalCitiesData;
      if(!stat) return;

      const data = cityDataToChartData(stat);

      if (this.info.component) {
        this.info.component.setState({ data });
      } else {
        this.info.component = ReactDOM.render(
          <CityInfo data={data} />,
          this.info._div
        );
      }
    };

    const mapStyle = (feature) => {
      return {
        fillColor: _.includes(
          _.map(this.citiesValue, "iso3166"),
          feature.properties["iso3166-2"]
        )
          ? "#FFC997"
          : "#E0E0E1",
        weight: 1,
        opacity: 1,
        color: "white",
        fillOpacity: 1,
      };
    };

    const onEachFeature = (feature, layer) => {
      return layer.on({
        mouseover: (e) => {
          let layer = e.target;

          layer.setStyle({
            weight: 3,
            ...(_.includes(
              _.map(this.citiesValue, "iso3166"),
              feature.properties["iso3166-2"]
            )
              ? { fillColor: "#FF7700" }
              : {}),
          });
        },
        mouseout: (e) => {
          this.boundaries.resetStyle(e.target);
        },
      });
    };

    this.boundaries = L.geoJSON(Map, {
      style: mapStyle,
      onEachFeature: onEachFeature,
    });

    this.boundaries.addTo(this.map);
    this.map.fitBounds(this.boundaries.getBounds());

    _.each(this.citiesValue, this.initializeCity.bind(this));

    this.map.on("resize", (e) => {
      return this.map.fitBounds(this.boundaries.getBounds());
    });
    this.info.addTo(this.map);
  }

  initializeCity(city) {
    const { iso3166, lat, lng } = city;
    const layer = _.find(
      this.boundaries.getLayers(),
      ({ feature }) => iso3166 == feature.properties["iso3166-2"]
    );

    if (!layer) return;
    const cityMarker = L.marker([lat, lng], {
      icon: cityIcon,
    });
    cityMarker.addTo(this.map);

    const tooltipContent = ReactDOMServer.renderToString(
      <CityTooltip city={city} />
    );

    let timeout;

    cityMarker.on({
      mouseover: () => {
        clearTimeout(timeout);

        cityMarker.setIcon(cityIconHover);
        layer.setStyle({ fillColor: "#FF7700", weight: 3 });
        this.info.update(city);

        if (!cityMarker._tooltip) {
          const tooltip = cityMarker.bindTooltip(tooltipContent, {
            className: "city-index-tooltip",
            opacity: 1,
            interactive: true,
            permanent: true,
          });
        }
      },
      mouseout: () => {
        cityMarker.setIcon(cityIcon);
        this.boundaries.resetStyle(layer);
        timeout = setTimeout(() => {
          cityMarker.unbindTooltip();
          this.info.update();
        }, 10);
      },
    });
  }
}
