require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("jquery-ui");

import $ from "jquery";
import "bootstrap";
import toastr from "toastr";

import "../utils/flatpickr";
import "../utils/fill_skill_bar";
import "../utils/forms";
import "../utils/latest_posts_fixed";
import "../utils/tabs";
import "../utils/main";
import 'swiper/swiper-bundle.min.css'

toastr.options = {
  closeButton: true,
  hideDuration: 1000,
  timeOut: 5000,
  extendedTimeOut: 2000,
};

global.toastr = toastr;

import "controllers";
