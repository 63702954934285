$(document).ready(function () {
  if ($(".tabs").length == 0) return;

  $(".tab__label")[0].classList.add("active");
  $(".tab")[0].classList.remove("d-none");
  $("input:radio")[0].checked = true;

  $(document).on("change", "input.tab__option", function () {
    $(".tab").addClass("d-none");
    $(".tab__label").removeClass("active");

    var year = $(this).attr("id");
    $(`#tab-${year}`).removeClass("d-none");
    $(`#label-${year}`).addClass("active");
  });
});
