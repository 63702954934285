function hideInputLabel(input) {
  let $input = $(input);

  if ($input.val() !== "" && !$input.is(":focus")) {
    $input.siblings()[0].style["display"] = "none";
  } else {
    $input.siblings()[0].style["display"] = "block";
  }
}

window.hideInputLabel = hideInputLabel;

$(document).ready(function () {
  $('#modal-window').add('.simple_form.new_user').add('.simple_form.edit_user')
    .on("focus", "input.form-control", function () {
      hideInputLabel(this);
    })
    .on("blur", "input.form-control", function () {
      hideInputLabel(this);
    })
    .on("click", ".toggle-password-view", function () {
      var $passwordInput = $(this).siblings(".user_password").find("input");
      var $passwordConfirmationInput = $(this)
        .parents(".form-inputs")
        .find(".user_password_confirmation input");
      $passwordInput.focus();

      if ($passwordInput[0].type === "password") {
        $passwordInput.prop("type", "text");
        $passwordConfirmationInput.prop("type", "text");
      } else {
        $passwordInput.prop("type", "password");
        $passwordConfirmationInput.prop("type", "password");
      }
    });
});
