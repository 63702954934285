import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["typeSelector"];
  static values = { url: String };

  selectQuestionType() {
    let questionType = this.typeSelectorTarget.value;

    if (questionType) {
      $.ajax({
        url: this.urlValue,
        data: { type: questionType },
      });
    }
  }
}
