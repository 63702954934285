$(document).ready(function () {
  $(document)
    .on("click", ".open-menu-mobile", function () {
      $(".menu").addClass("active");
      $("body").addClass("no-scroll");
    })
    .on("click", ".close-menu-mobile", function () {
      $(".menu").removeClass("active");
      $("body").removeClass("no-scroll");
    })
    .on("click", ".expandable", function () {
      $(this).find(".answer").toggleClass("not-expanded");
      $(this).find(".arrow").toggleClass("expanded");
    });
});
