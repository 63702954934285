import { Controller } from "stimulus";
import Swiper, { Navigation, Grid } from "swiper";

export default class extends Controller {
  static targets = ["swiper", "material"];

  connect() {
    Swiper.use([Navigation, Grid]);

    if (this.hasMaterialTarget) {
      this.swiper = new Swiper(this.materialTarget, {
        autoHeight: false,
        spaceBetween: 24,
        slidesPerView: 1,
        grid: {
          rows: 1,
          fill: "row",
        },
        navigation: {
          prevEl: ".carousel-btn--prev",
          nextEl: ".carousel-btn--next",
        },
        breakpoints: {
          992: {
            slidesPerView: 2,
          }
        },
      });
    } else {
      this.swiper = new Swiper(this.swiperTarget, {
        autoHeight: false,
        spaceBetween: 24,
        slidesPerView: 1,
        grid: {
          rows: 2,
          fill: "row",
        },
        navigation: {
          prevEl: ".carousel-btn--prev",
          nextEl: ".carousel-btn--next",
        },
        breakpoints: {
          1200: {
            slidesPerView: 6,
          },
          992: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          350: {
            slidesPerView: 2,
          },
        },
      });
    }

  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }
}
