$(document).ready(function() {
  var fixmeTop = $('aside.latest-posts-sidebar').offset();

  if (fixmeTop === undefined) return

  $(window).scroll(function() {
    var currentScroll = $(window).scrollTop() + 10;

    if (currentScroll >= fixmeTop.top) {
      $('aside.latest-posts-sidebar').addClass('sticked')
    } else {
      $('aside.latest-posts-sidebar').removeClass('sticked')
    }
  });
});
