import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static values = {
    disableOptions: Object,
    disabledByOptions: Object,
    disabledByMaxCount: Boolean,
  };

  connect() {
    this.triggerEvent();
  }

  change(e) {
    this.triggerEvent();
  }

  triggerEvent() {
    if (_.chain(this.disableOptionsValue).values().flatten().isEmpty().value())
      return;

    if (this.element.checked) {
      document.dispatchEvent(
        new CustomEvent("disable-options", {
          detail: {
            disableOptions: this.disableOptionsValue,
            optionValue: this.element.dataset.optionIdentifier,
            questionValue: this.element.dataset.questionIdentifier,
          },
        })
      );
    } else {
      document.dispatchEvent(
        new CustomEvent("enable-options", {
          detail: {
            disableOptions: this.disableOptionsValue,
            optionValue: this.element.dataset.optionIdentifier,
            questionValue: this.element.dataset.questionIdentifier,
          },
        })
      );
    }
  }

  disabledByOptionsValueChanged() {
    this.toggleDisabled();
  }

  disabledByMaxCountValueChanged() {
    this.toggleDisabled();
  }

  toggleDisabled() {
    this.element.disabled =
      this.disabledByMaxCountValue ||
      !_.chain(this.disabledByOptionsValue)
        .values()
        .flatten()
        .isEmpty()
        .value();
    if (this.element.disabled) {
      this.element.checked = false;
      this.triggerEvent();
    }
  }
}
