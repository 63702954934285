import React from "react";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import ManIcon from "./man_icon.svg";
import WomanIcon from "./woman_icon.svg";

Chart.register(...registerables, ChartDataLabels);

export const CityTooltip = ({ city }) => {
  const { name, city_url, score, year } = city;

  return (
    <div className="position-relative">
      <div className="row">
        <div className="col-auto d-flex align-items-center">
          <span className="index-score">{score}</span>
        </div>
        <div className="mx-n2" />
        <div className="col-auto">
          <div className="city-name">{name}</div>
          <div className="poll-year">Результат {year}</div>
          <a href={city_url} className="learn-more">
            БІЛЬШЕ
            <i className="fas fa-angle-right ml-1" />
          </a>
        </div>
      </div>
    </div>
  );
};

export class CityInfo extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();

    this.state = {
      data: props.data,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.eq(this.props, nextProps) || !_.eq(this.state, nextState);
  }

  componentDidUpdate() {
    this.chart.data.datasets[0].data = this.state.data.chart_data;
    this.chart.update();
  }

  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext("2d");

    this.chart = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: ["14-18", "19-23", "24-35"],
        datasets: [
          {
            data: this.state.data.chart_data,
            backgroundColor: [
              "rgba(255,255,255,1)",
              "rgba(255,255,255,1)",
              "rgba(255,255,255,1)",
            ],
            hoverBackgroundColor: [
              "rgba(255,255,255,1)",
              "rgba(255,255,255,1)",
              "rgba(255,255,255,1)",
            ],
            maintainAspectRatio: false,
            datalabels: {
              color: "rgba(255,255,255,1)",
              align: "start",
              anchor: "end",
              offset: -17,
              textAlign: "center",
              font: {
                family: "'Manrope'",
                size: 10,
                weight: 800,
                lineHeight: 1.4,
              },
              formatter: (value) => `${value}%`,
            },
          },
        ],
      },
      options: chartOptions,
    });
  }

  render() {
    const {
      woman_requests_percentage,
      man_requests_percentage,
      total_requests,
    } = this.state.data;

    return (
      <div className="index-map-info__container">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="index-map-info__count">{total_requests}</div>
              </div>
              <div className="col-12">
                <div className="index-map-info__text">
                  респондентів, які пройшли опитування за весь час
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-4 pr-0">
                <div className="row">
                  <div className="col-12">
                    <div className="index-map-info__stat-title">Стать</div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="index-map-info__gender-stat">
                      <span className="index-map-info__icon-wrapper">
                        <img src={ManIcon} />
                      </span>
                      {` - ${man_requests_percentage}%`}
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="index-map-info__gender-stat">
                      <span className="index-map-inf__icon-wrapper">
                        <img src={WomanIcon} />
                      </span>
                      {` - ${woman_requests_percentage}%`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-12">
                    <div className="index-map-info__stat-title">Вік</div>
                  </div>
                  <div className="col-12 mt-2">
                    <canvas ref={this.chartRef} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const chartOptions = {
  layout: {
    padding: {
      top: 10,
    },
  },
  animations: {
    y: {
      easing: "easeInCubic",
      duration: 500,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      display: true,
      grid: {
        padding: 0,
        backdropPadding: 0,
        drawBorder: false,
        drawTicks: false,
        color: "transparent",
      },
      ticks: {
        color: "rgba(255,255,255,1)",
        padding: 0,
        font: {
          family: "'Manrope'",
          size: 10,
          weight: 300,
          lineHeight: 1.4,
        },
      },
      max: 100,
      min: 0,
      beginAtZero: true,
    },
    y: {
      display: false,
    },
  },
};
