import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["categoriesSelect"];
  static values = { url: String };

  changeCategory() {
    let url = `${this.urlValue}?q[category_eq]=${this.categoriesSelectTarget.value}`;

    Rails.ajax({
      type: "GET",
      url: url,
      success: function (res) {
        history.pushState({}, null, url);
      },
    });
  }
}
