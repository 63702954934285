import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["collapsible", "container"];

  toggleCities(e){
    e.preventDefault();

    this.containerTargets.forEach(function(element){
      if (element.style.maxHeight) {
        element.style.maxHeight = null;
        element.classList.remove("expanded")
      } else {
        element.classList.add("expanded")
        element.style.maxHeight = element.scrollHeight + 'px'
      } 
    })
  }
}
