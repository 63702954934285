import { Controller } from "stimulus";
import YouTubePlayer from "youtube-player";

export default class extends Controller {
  static values = { videoId: String, videoYoutubeId: String };
  static targets = ["playlistVideos"];

  connect() {
    this.scrollToCurrent();
  }

  scrollToCurrent() {
    const currentTarget = this.playlistVideosTarget.querySelector(
      `#video_${this.videoIdValue}`
    );

    this.playlistVideosTarget.scrollTo({
      top: currentTarget.offsetTop,
      behavior: "smooth",
    });
  }

  videoIdValueChanged() {
    this.player.loadVideoById(this.videoYoutubeIdValue);
    if (!this.notFirstRender) {
      this.notFirstRender = true;
      this.player.stopVideo();
    }
    this.startEventTriggered = false;
  }

  get player() {
    if (!this._player) {
      this._player = YouTubePlayer("lecture-video-embed", {
        playerVars: { autoplay: 0, controls: 1 },
      });

      this._player.on("stateChange", ({ data }) => {
        if (data == 1 && !this.startEventTriggered) {
          this.startEventTriggered = true;
          $.ajax({
            type: "POST",
            url: `/videos/${this.videoIdValue}/start`,
          });
        }

        if (data == 0) {
          $.ajax({
            type: "POST",
            url: `/videos/${this.videoIdValue}/finish`,
            complete: () => {
              setTimeout(() => {
                this.scrollToCurrent();
              }, 50);
            },
          });
        }
      });
    }

    return this._player;
  }
}
