import { Controller } from "stimulus";
import * as clipboard from "clipboard-polyfill/text";

export default class extends Controller {
  static values = { code: String };

  togglePromoCode() {
    clipboard.writeText(this.codeValue);
    toastr.success("Промокод скопійовано");
  }
}
