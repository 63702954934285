import flatpickr from 'flatpickr';
import 'flatpickr/dist/themes/airbnb.css';
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";

$(function() {
  flatpickr('.datepicker', {
    altInput: true,
    locale: Ukrainian
  })

  $('#modals-container').on('show.bs.modal', function() {
    flatpickr('.datepicker', {
      altInput: true,
      locale: Ukrainian
    })
  })

  $('#episodes-container').on('cocoon:after-insert', function(e) {
    flatpickr('.datepicker', {
      altInput: true,
      locale: Ukrainian
    })
  })
});
